@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    margin: 0;
    font-family: 'Barlow Condensed', sans-serif;
    background: repeating-linear-gradient(to right, #256571, #5ABCCB 20%, #6F984A 40%, #F4A553 60%, #E9617C 80%, #256571 100%);
    background-size: 200% 100%;
    -webkit-animation: gradientCycle 10s linear alternate infinite;
            animation: gradientCycle 10s linear alternate infinite;
  }
  
  @-webkit-keyframes gradientCycle {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: left;
    }
  }
  
  @keyframes gradientCycle {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: left;
    }
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body::-webkit-scrollbar {
    display: none;
}

body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

html {
    background-color: "#4C4C4C";
}

div {
    -webkit-user-select: none;
    user-select: none;
    -o-user-select: none;
}

.logo {
    width: 90%;
}

.mainWrapper h1 {
    color: var(--button-color);
    font-size: 3rem;
    margin-top: 1rem;
}

.mainWrapper h2 {
    color: #0c0b0b;
    font-size: 1.5rem;
    margin-top: 1rem;
    text-align: center;
}

.priceWrapper {
    margin: 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.nftImages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.nftImages div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nftImages img {
    width: 100%;
    height: auto;
}

.nftImages h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
}

@media (max-width: 600px) {
    .mainWrapper {
        width: 100% !important;
    }

    .mainWrapper h1 {
        font-size: 1.5rem;
        text-align: center;
    }

    .contractAddress {
        font-size: 1rem !important;
    }

    .priceList {
        font-size: 1.5rem !important;
    }

    .nftImages {
        align-items: flex-start;
    }

    .nftImages img {
        width: 100%;
        height: auto;
        display: block;
        margin: auto;
    }

    .nftImages h2 {
        font-size: .9rem;
    }

    .connectedAccount {
        font-size: 1rem !important;
    }
}
